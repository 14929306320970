import React from "react";
import { useHistory, Redirect } from "react-router";
import Flex from "app/components/Flex";
import Icon, { Lock } from "app/components/Icon";
import { H1 } from "app/components/Typography";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import Breadcrumbs from "app/components/Breadcrumbs";
import { useGetUserProfile } from "hooks/User";
import { PlaylistInfo } from "./styles";
import { useGetMyPersonalizedPlaylist } from "../hooks";
import { ClassRow } from "../PlaylistClassRow";

export default () => {
  const history = useHistory();
  const {
    personalizedPlaylist,
    loadingPersonalizedPlaylist,
  } = useGetMyPersonalizedPlaylist();
  const [profileData] = useGetUserProfile();
  const { username } = profileData;

  if (loadingPersonalizedPlaylist) {
    return <LoaderFullCentered />;
  }

  if (!personalizedPlaylist) {
    return <Redirect to="/playlists/created" />;
  }

  return (
    <>
      <Flex flexDirection="column" p="24px">
        <Breadcrumbs
          onClick={() => {
            history.push("/playlists/created");
          }}
        >
          &lt; Back to Playlists
        </Breadcrumbs>
        <H1>Your Weekly Recommendations</H1>
        <Flex my="8px" gap="12px" alignItems="center">
          {username && (
            <>
              <PlaylistInfo>Created for @{username}</PlaylistInfo>
              <div> • </div>
            </>
          )}
          <PlaylistInfo>
            {`${personalizedPlaylist.length} ${
              personalizedPlaylist.length === 1 ? "Class" : "Classes"
            }`}
          </PlaylistInfo>
          <div> • </div>
          <PlaylistInfo>
            <Flex alignItems="center">
              <Icon as={Lock} mr="4px" />
              {"Private"}
            </Flex>
          </PlaylistInfo>
        </Flex>
        <Flex flexDirection="column" my="16px" gap="16px">
          {personalizedPlaylist &&
            personalizedPlaylist.map(classData => (
              <ClassRow key={classData.id} classData={classData} />
            ))}
        </Flex>
      </Flex>
    </>
  );
};
